import { Component } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent {
  searchTerm: string = '';
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  categories = [
    { name: 'Procurement Strategy', count: 5, link: '/procurement-strategy' },
    { name: 'Procurement Technology', count: 12, link: '/procurement-technology' },
    { name: 'E-Procurement', count: 8, link: '/e-procurement' },
    { name: 'Supplier Management', count: 7, link: '/supplier-anagement' },
  ];

  recentPosts = [
    {
      title: 'Navigating eRFx Processes: Best Practices for Buyers and Suppliers.',
      image: '../../../assets/Blog/Buyers-and-Suppliers-blog1.jpg',
      link: '/navigating-eRFx-processes',
      date: 'July 1, 2024',
    },
    {
      title: 'The Future of eAuctions: Leveraging Technology for Better Deals.',
      image: '../../../assets/Blog/Future-eAuctions-blog2.jpg',
      link: '/future-of-eAuctions',
      date: 'Aug 10, 2024',
    },
    {
      title: 'Building Strong Buyer-Supplier Relationships in a Digital World.',
      image: '../../../assets/Blog/buyer-supplier-reln.jpg',
      link: '/buyer-supplier-relationship',
      date: 'April 20, 2023',
    },
  ];

  onSearch() {
    console.log('Search term:', this.searchTerm);
  }
}
