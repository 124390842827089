<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../../assets/products/source-matrix.png);">
    <div class="container">
        <h1>Source Matrix - A Strategic Sourcing and Project Management Services</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Source Matrix</li>
            </ol>
        </nav>
    </div>
</div>
<!-- feature 1 -->
<section id="exfeatures" class="exfeatures section" style="background-color: #ececec;">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Strategic Sourcing</h2>
                    <p class="description">Strategic sourcing is a critical component of the procurement process, aimed
                        at optimizing an organization's purchasing activities by aligning them with its long-term
                        business objectives. On our platform, we provide comprehensive strategic sourcing services
                        designed to help organizations identify, evaluate, and select suppliers that offer the best
                        value over the long term.</p>
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div *ngFor="let feature of exploreFeatures" class="col-lg-4 col-md-6" data-aos="fade-up"
                [attr.data-aos-delay]="feature.delay">
                <div [ngClass]="{'flipped': flippedCard === feature.id}"
                    class="features-item shadow-md flex-col card-container" (mouseenter)="handleMouseEnter(feature.id)"
                    (mouseleave)="handleMouseLeave()">
                    <div class="card">
                        <div class="card-front" style="align-items: center;display:flex;">
                            <i [class]="feature.Icon" [ngStyle]="{'color': feature.color}" size="30"
                                class="cardsSvg"></i>
                            <h3>{{ feature.title }}</h3>
                        </div>
                        <div class="card-back">
                            <i [class]="feature.Icon" [ngStyle]="{'color': feature.color}" size="30"
                                class="svgicon"></i>
                            <p class="cardBack">{{ feature.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- feature 2 -->
<section id="services" class="services section">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Project Management Services</h2>
                    <p class="description">Effective project management is essential to the success of any
                        procurement-related initiative. Procure Masster offers robust project management services
                        tailored to the unique needs of procurement professionals.</p>
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div *ngFor="let service of services" class="col-lg-4 col-md-6" data-aos="fade-up"
                [attr.data-aos-delay]="service.delay">
                <div class="service-item position-relative">
                    <div class="icon">
                        <i [class]="service.icon" style="color: #0075f9"></i>
                    </div>
                    <a class="stretched-link text-decoration-none">
                        <h3>{{ service.title }}</h3>
                    </a>
                    <p>{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>