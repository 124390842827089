<!-- hero section -->
<div class="page-title dark-background" data-aos="fade" style="background-image: url(../../assets/Faq/faq.png);">
    <div class="container">
        <h1>FAQs</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">FAQs</li>
            </ol>
        </nav>
    </div>
</div>
<!-- FAQ's -->
<section id="faq" class="faq section light-background" data-builder="section">
    <div class="container section-title" data-aos="fade-up">
        <h2>Frequently Asked Questions (FAQs)</h2>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center gy-4">
            <div class="col-lg-4">
                <img src="../../assets/Faq/5.png" class="new-img" alt="faq"/>
            </div>
            <div class="col-lg-8 d-flex flex-column order-2 order-lg-1">
                <div class="faq-container">
                    <div class="faq-item" *ngFor="let faq of faqs; let i = index" [class.faq-active]="faq.isActive" data-aos="fade-right">
                        <h3 (click)="toggleFaq(i)">
                            <span class="num">{{ i + 1 }}.</span> <span>{{ faq.question }}</span>
                        </h3>
                        <div class="faq-content" *ngIf="faq.isActive">
                            <p *ngFor="let desc of faq.description" [innerHTML]="desc" (click)="handleClick($event)">
                            </p>
                        </div>
                        <i class="faq-toggle bi bi-chevron-right" (click)="toggleFaq(i)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  -->