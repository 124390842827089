<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../assets/contact/contact-hero.png);">
    <div class="container">
        <h1>Contact Us</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Contact</li>
            </ol>
        </nav>
    </div>
</div>
<!--  -->
<section id="contact" class="contact section">
    <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="info-wrap" data-aos="fade-up" data-aos-delay="200">
            <div class="row gy-5">
                <div class="col-lg-4">
                    <div class="info-item d-flex align-items-center">
                        <i class="bi bi-geo-alt flex-shrink-0"></i>
                        <div>
                            <h3>Address</h3>
                            <p>Near Barcode ,Parkland,
                                Moshi,<br> Pune, Maharashtra,
                                India - 412105
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="info-item d-flex align-items-center">
                        <i class="bi bi-telephone flex-shrink-0"></i>
                        <div>
                            <h3>Call Us</h3>
                            <p><a target="_blank" href="tel:9209171373" class="text-decoration-none text-black">+91
                                    9209171373</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="info-item d-flex align-items-center">
                        <i class="bi bi-envelope flex-shrink-0"></i>
                        <div>
                            <h3>Email Us</h3>
                            <p><a target="_blank" href="mailto:sales@procuremasster.com"
                                    class="text-decoration-none text-black">sales@procuremasster.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row gy-4" data-aos="fade-up" data-aos-delay="300">
            <div class="col-lg-6">
                <div id="map" style="width: 100%; height: 100%;">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60475.75435105344!2d73.76084392167965!3d18.675899799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b70992f06de9%3A0x1be893f6ff686be3!2sBARCODE%20BY%20RAMA%20GROUP!5e0!3m2!1sen!2sin!4v1724647559043!5m2!1sen!2sin"
                        width="100%" height="350" frameborder="0" style="border:0" allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-6">
                <form name="editForm" role="form" novalidate (ngSubmit)="onSubmit()" [formGroup]="editForm"
                    class="contact-form" data-aos="fade" data-aos-delay="100">
                    <div class="row gy-4">
                        <div class="col-md-6">
                            <input type="text" name="name" class="form-control" id="name" formControlName="name"
                                placeholder="Your Name" />
                            <div
                                *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)">
                                <small class="form-text text-danger" *ngIf="editForm.get('name')?.errors?.['required']">
                                    Name is required.
                                </small>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <input type="text" name="contactNumber" class="form-control" id="contactNumber"
                                formControlName="contactNumber" placeholder="Your Number" />
                            <div
                                *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('contactNumber')!.dirty ||editForm.get('contactNumber')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('contactNumber')?.errors?.['required']">Contact
                                    Number is
                                    required.</small>
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please
                                    enter a 10-digit
                                    Mobile Number</small>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <input type="text" name="email" class="form-control" id="email" formControlName="email"
                                placeholder="Your Email" />
                            <div
                                *ngIf="editForm.get('email')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('email')?.errors?.['required']">Email is
                                    required</small>
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('email')?.errors?.['email']">Please enter valid
                                    email.</small>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <textarea class="form-control" name="comments" rows="3" placeholder="Requirements"
                                formControlName="comments"></textarea>
                            <div
                                *ngIf="editForm.get('comments')!.invalid && (editForm.get('comments')!.dirty || editForm.get('comments')!.touched)">
                                <small class="form-text text-danger"
                                    *ngIf="editForm.get('comments')?.errors?.['required']">This field
                                    is required</small>
                            </div>
                        </div>

                        <div *ngIf="showThankYouMessage" class="text-center text-success">
                            Thank You for contacting us. Our team will get back to you
                            shortly.
                        </div>

                        <div class="col-md-12 text-center">
                            <button [disabled]="editForm.invalid || isSaving" type="submit" value="send">Submit</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Map Section -->

        </div>
    </div>
</section>