import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {
  testimonials = [
    {
      img: '',
      text: `Procure Masster has completely transformed our approach to strategic sourcing. The online RFx and e-auction capabilities have streamlined our sourcing processes, allowing us to conduct competitive events more efficiently than ever before. The platform's RFx tools are robust and user-friendly, enabling us to easily create and manage requests for proposals and quotations. The e-auction feature, in particular, has driven significant cost savings by fostering real-time competition among suppliers. With Procure Master, we've been able to make more data`,
      client: 'Manoj Patil, Director, Thermal Engg, Pune'
    },
    {
      img: '',
      text: `Procure Masster has been instrumental in helping us identify and onboard new vendors, especially for the development of new parts. The platform’s vendor discovery and qualification tools have expanded our supplier base, allowing us to source high-quality components from the best suppliers worldwide. The seamless RFx process has made it easier to engage with potential vendors, gather critical information, and evaluate their capabilities. As we develop new parts, the collaborative features of Procure Masster have facilitated better communication and alignment with suppliers, ensuring that our specifications are met precisely. This has not only accelerated our product development cycle but also improved the quality and reliability of our new parts. Procure Masster is a game-changer for any company focused on innovation and supplier collaboration.`,
      client: 'Shahaji Phadtare, Director, Mechatronics Conveyors '
    },
  ];
}
