<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../../assets/products/eauction-proo.png);">
    <div class="container">
        <h1>eAuction Pro</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">eAuction Pro</li>
            </ol>
        </nav>
    </div>
</div>
<!-- key features -->
<section id="services" class="services section light-background">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">eAuction: Revolutionizing Procurement with Competitive Digital Bidding</h2>
                    <p class="description">The <b>eAuction</b> module of our procurement platform is designed to bring
                        efficiency, transparency, and competitiveness to the procurement process. As a digital
                        marketplace, it facilitates real-time bidding, enabling organizations to secure the best
                        possible deals from suppliers. Here’s how eAuction stands out as a critical component of modern
                        procurement:</p>
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div class="col-lg-4 col-md-6" *ngFor="let service of features" [attr.data-aos-delay]="service.delay"
                data-aos="fade-up">
                <div class="service-item position-relative">
                    <div class="icon">
                        <i [class]="service.icon"></i>
                    </div>
                    <a class="stretched-link text-decoration-none">
                        <h3>{{ service.title }}</h3>
                    </a>
                    <p>{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Advantages -->
<section id="value" class="value section">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Advantages of eAuction</h2>
                </div>
            </div>
        </div>
        <div class="value-image">
            <img src="../../../assets/products/adv.jpg" alt="Centered Image" />
        </div>
    </div>
</section>
<!-- benefits -->
<section id="whyUs" class="whyUs section light-background">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Types of eAuction</h2>
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div class="col-lg-6 col-md-6 d-flex" *ngFor="let service of WhyUs" [attr.data-aos-delay]="service.aosDelay"
                data-aos="fade-up">
                <div class="service-item position-relative">
                    <div class="items" style="display: flex;align-items:center;">
                        <div class="icon"><i [class]="service.icon + ' icon'"></i></div>
                        <h4><a class="stretched-link text-decoration-none">{{ service.title }}</a></h4>
                    </div>
                    <p [innerHTML]="service.details" class="para"></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  -->