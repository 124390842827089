<!-- hero section -->
<div class="page-title dark-background" data-aos="fade" style="background-image: url(../../assets/Blog/Blog-hero.jpg);">
    <div class="container">
        <h1>Blog</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Blog</li>
            </ol>
        </nav>
    </div>
</div>
<!-- blog  -->
<section class="product">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 mb-4" *ngFor="let product of portfolio">
                <div class="boxes shadow-lg">
                    <div class="product_box">
                        <div class="prod_img">
                            <img [src]="product.imageUrl" alt="Product" />
                        </div>
                        <h1>{{ product.desc }}</h1>
                        <a [routerLink]="product.link" class="text-decoration-none text-white">
                            <button type="button" class="blog_btn" (click)="scrollToTop()">Read More</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>