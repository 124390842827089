import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  title: string = 'About Us';

  WhyUs = [
    {
      icon: 'bx bxs-network-chart',
      title: 'Comprehensive Supplier Network',
      description: 'Access a vast network of verified suppliers across various industries, ensuring you find the best products and services.',
      aosDelay: 100
    },
    {
      icon: 'bx bxs-devices',
      title: 'User-Friendly Interface',
      description: 'Our platform is designed with simplicity in mind, making it easy for users to navigate and manage their procurement activities.',
      aosDelay: 200
    },
    {
      icon: 'bi-bar-chart-line-fill',
      title: 'Advanced Analytics',
      description: 'Gain insights into your procurement processes with our advanced analytics tools, helping you make data-driven decisions.',
      aosDelay: 300
    },
    {
      icon: 'bx bxs-been-here',
      title: 'Security and Compliance',
      description: 'We prioritize the security of your data and ensure compliance with industry standards, so you can focus on what matters most.',
      aosDelay: 400
    }
  ];

  additionalParagraphs: string[] = [
    `At Procure Masster, we believe that procurement isn't just a process—it's a strategic asset. With over two decades of experience in strategic sourcing, vendor development, and logistics management, our founder has crafted a vision for transforming procurement into a value-creating function.`,
    `Our web-based procurement portal is designed to elevate your procurement activities to new heights of efficiency, transparency, and excellence. Whether you're a buyer or supplier, our platform offers robust tools for eAuction, Get new vendor onboarding, and more. Discover how we can transform your procurement from a cost center into a profit center.`,
    `In today's fast-paced business landscape, procurement excellence is more than just a goal; it's a necessity. Organizations that master the art of procurement are positioned to outperform their competitors, streamline operations, and drive sustainable growth. Welcome to Procure Masster, where excellence in procurement is our mantra and mission.`
  ];

  teamMembers = [
    {
      imgSrc: '',
      name: 'Santosh S Patil',
      position: ' Founder & CEO',
      description: 'Santosh S Patil is the visionary behind Procure Masster, bringing over 20 years of experience in the procurement and supply chain industry. Before founding Procure Masster, he held key positions as Global Purchase Head across various industry sectors, including Industrial Equipment, Machine Tools, Transmission, Automotive, Project Industry (Oil & Gas) and Technical Textiles. During his tenure, he identified the need for a more streamlined and technologically advanced procurement process.',
      description1: `He has been recognized with multiple awards for his outstanding contributions to the field. He was honored as the "Strategic Sourcing Leader of the Year 2023" by UBS Forums in Mumbai and received the "Best Procurement Transformation 2023" award from Inventicon Business Intelligence, Mumbai. Additionally, he has earned a Leadership Award for "Professional Excellence in Procurement, Supply Chain, and Vendor Management" from CE Worldwide in both 2023 and 2021.`,
      desc3: 'He is also a Certified Professional in Strategic Sourcing from ISM India, affiliated with ISM USA.',
      aosDelay: 100,
      social: {
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: ''
      }
    },
    {
      imgSrc: '',
      name: 'Smita Pawar',
      position: 'COO',
      description: 'Smita Pawar has extensive experience in operations and supply chain management, having spent 10 years in leadership roles . As COO, she is responsible for optimizing our internal processes, ensuring that our platform delivers a seamless experience to our users. She also manage the day-to-day operational aspects of the business, driving efficiency and scalability.',
      description1: `She is committed to building a culture of continuous improvement, where innovation and operational excellence go hand in hand.`,
      aosDelay: 200,
      social: {
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: ''
      }
    }
  ];

  iconBoxes = [
    {
      icon: 'bx bx-cube-alt',
      title: 'Our Mission',
      description: `At Procure Masster our mission is to revolutionize the procurement process by connecting businesses with trusted suppliers and providing a seamless, transparent, and efficient platform for all procurement needs. We aim to empower organizations to make smarter purchasing decisions, reduce costs, and foster long-lasting supplier relationships.`,
      delay: 100
    },
    {
      icon: 'bx bxs-low-vision',
      title: 'Our Vision',
      description: `We envision a world where procurement is simplified, where businesses of all sizes have equal access to quality suppliers, and where transparency and innovation drive the procurement industry forward. Our platform is designed to be the cornerstone of this future, enabling growth and success for our users.`,
      delay: 200
    },
    {
      icon: 'bx bxs-book',
      title: 'Our Story',
      description: `Procure Masster was born out of the frustration with outdated and inefficient procurement processes. Our founders, experienced professionals in supply chain management, recognized the need for a modern solution that leverages technology to streamline procurement. What started as a simple idea has grown into a comprehensive platform that serves thousands of businesses worldwide.`,
      delay: 300
    }
  ];
}


