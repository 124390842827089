import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './faq.component';

@NgModule({
    declarations: [FaqComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'faqs',
                component: FaqComponent,
            },
        ]),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FaqModule { }
