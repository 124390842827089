import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isDisabled: boolean = true;
  isMenuOpen = false;
  isDropdownOpen = false;
  isLoginDisabled: boolean = true;
  constructor(public router: Router) { }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  toggleDropdown(event: Event) {
    event.preventDefault();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  showDropdown() {
    this.isDropdownOpen = true;
  }

  hideDropdown() {
    this.isDropdownOpen = false;
  }

  dropdownItems = [
    { label: 'Associate', url: 'http://ec2-user@ec2-16-170-228-28.eu-north-1.compute.amazonaws.com:8080/account/register', icon: 'bx bxs-user' },
    { label: 'Supplier', url: 'http://ec2-16-170-228-28.eu-north-1.compute.amazonaws.com:8080/account/buyerLogin', icon: 'bx bxs-user-detail' },
    { label: 'Buyer', url: 'http://ec2-16-170-228-28.eu-north-1.compute.amazonaws.com:8080/account/buyerLogin', icon: 'bi bi-people-fill' }
  ];

  resourceItems = [
    { label: 'Blogs', url: '/blog', icon: 'bx bxl-blogger' },
    { label: `FAQs`, url: '/faqs', icon: 'bx bx-question-mark' },
  ];

  productItems = [
    { label: 'RFX Master - RFI/RFP/RFQ', url: '/rfx-master', icon: 'bx bxs-bar-chart-square' },
    { label: `eAuction Pro`, url: '/eAuction-Pro', icon: 'bx bxs-fast-forward-circle' },
    { label: `Get Your New Vendor`, url: '/new-vendor', icon: 'bx bxs-user-plus' },
    { label: `Part Master - New Part Development`, url: '/part-master', icon: 'bx bxs-file-plus' },
    { label: `Source Matrix - A Strategic Sourcing & <br> Project Management Services`, url: '/source-matrix', icon: 'bx bxs-book-alt' },
  ];
}


