import { Component } from '@angular/core';

@Component({
  selector: 'app-new-vendor',
  templateUrl: './new-vendor.component.html',
  styleUrls: ['./new-vendor.component.scss']
})
export class NewVendorComponent {
  features = [
    {
      icon: 'bx bxs-file-plus',
      title: 'Supplier Discovery',
      description: `The platform facilitates the discovery of potential new vendors through a comprehensive database that spans various industries and regions. Advanced search filters allow procurement teams to narrow down suppliers based on criteria such as product categories, certifications, geographical locations, and previous performance.`,
      delay: 100
    },
    {
      icon: 'bx bxs-user-check',
      title: 'Qualification Process',
      description: `The platform automates the qualification process by standardizing the evaluation criteria. This includes assessing the supplier’s financial stability, compliance with industry standards, quality management systems, and capacity to meet demand. The tool also allows for customized qualification questionnaires, ensuring that the unique requirements of each organization are met.`,
      delay: 200
    },
    {
      icon: 'bx bx-broadcast',
      title: 'Collaboration & Communication',
      description: `Effective communication with potential vendors is streamlined through integrated messaging and document sharing features. This ensures that all stakeholders are on the same page and that the onboarding process is as efficient as possible. Collaboration tools also enable cross-functional teams to work together in evaluating new suppliers.`,
      delay: 300
    },
    {
      icon: 'bx bxl-graphql',
      title: 'Performance Tracking',
      description: `Once a new vendor is onboarded, the platform enables continuous performance tracking. This includes monitoring delivery timelines, quality of goods or services, adherence to contracts, and responsiveness to issues. Over time, this data helps in making informed decisions about long-term partnerships.`,
      delay: 400
    },
    {
      icon: 'bx bx-street-view',
      title: 'Risk Management',
      description: `The platform integrates risk assessment tools that evaluate the potential risks associated with new vendors. Factors such as geopolitical risks, supplier dependency, and market volatility are considered. This allows procurement teams to make more informed decisions and develop contingency plans if needed.`,
      delay: 500
    },
    {
      icon: 'bx bxs-user-voice',
      title: 'Compliance & Auditing',
      description: 'Ensuring that new vendors comply with regulatory requirements is critical. The platform provides tools for managing compliance documentation, conducting audits, and maintaining records. This reduces the administrative burden on procurement teams and helps avoid potential legal and regulatory issues.',
      delay: 600
    },
    {
      icon: 'bx bxs-bar-chart-alt-2',
      title: 'Cost Analysis & Negotiation',
      description: 'The platform includes features for conducting cost analysis and supporting negotiation strategies. By providing detailed cost breakdowns and market comparisons, it empowers procurement professionals to negotiate better terms with new vendors, ultimately leading to cost savings.',
      delay: 700
    }
  ];
}
