<div class="icons-container">
  <a target="_blank" href="tel:+91 9209171373" class="call-button btn"><i class="bi bi-whatsapp"></i></a>
</div>
<!--  -->
<header id="header" class="header fixed-top d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="logo">
      <a routerLink="/" (click)="scrollToTop()"> <img src="../../assets/home/new_procureLogo.jpeg" /></a>
    </div>
    <nav id="navbar" class="navbar order-last order-lg-0">
      <ul class="desktop-menu d-flex justify-content-center" *ngIf="!isMenuOpen">
        <li><a class="nav-link" routerLink="/" (click)="scrollToTop()" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Home</a></li>
        <li><a class="nav-link" (click)="scrollToTop()" [routerLink]="'/about'" routerLinkActive="active">About</a></li>
        <!-- products -->
        <li class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
          <a class="dropdown-toggle" (click)="toggleDropdown($event)">Products</a>
          <ul *ngIf="isDropdownOpen">
            <li *ngFor="let item of productItems; let i = index" class="dropdown-item" [routerLink]="item.url"
              (click)="scrollToTop()" [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'">
              <div class="item-row">
                <i [class]="item.icon"></i>
                <a [routerLink]="item.url" (click)="scrollToTop()" [innerHTML]="item.label" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"></a>
              </div>
            </li>
          </ul>
        </li>
        <!-- resources -->
        <li class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
          <a class="dropdown-toggle" (click)="toggleDropdown($event)">Resources</a>
          <ul *ngIf="isDropdownOpen">
            <li *ngFor="let item of resourceItems; let i = index" class="dropdown-item" [routerLink]="item.url"
              (click)="scrollToTop()" [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'">
              <div class="item-row">
                <i [class]="item.icon"></i>
                <a [routerLink]="item.url" (click)="scrollToTop()" [innerHTML]="item.label" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"></a>
              </div>
            </li>
          </ul>
        </li>
        <li><a class="nav-link" (click)="scrollToTop()" routerLink='/contact' routerLinkActive="active">Contact</a></li>
        <!-- login -->
        <li *ngIf="!isLoginDisabled" class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
          <a class="dropdown-toggle" (click)="toggleDropdown($event)">Login</a>
          <ul *ngIf="isDropdownOpen">
            <li *ngFor="let item of dropdownItems; let i = index" class="dropdown-item" [routerLink]="item.url"
              [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'" (click)="scrollToTop()">
              <div class="item-row">
                <i [class]="item.icon"></i>
                <a [routerLink]="item.url" (click)="scrollToTop()" [innerHTML]="item.label" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                </a>
              </div>
            </li>
          </ul>
        </li>
        <!--  -->
      </ul>
      <nav class="navbar order-last order-lg-0 navbar-mobile" *ngIf="isMenuOpen">
        <ul>
          <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="/" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Home</a></li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="about"
              routerLinkActive="active">About</a></li>
          <!-- products -->
          <li class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
            <a class="dropdown-toggle" (click)="toggleDropdown($event)">Products</a>
            <ul *ngIf="isDropdownOpen">
              <li *ngFor="let item of productItems; let i = index" class="dropdown-item" [routerLink]="item.url"
                [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'"
                (click)="scrollToTop(); closeMenu()">
                <div class="item-row">
                  <i [class]="item.icon"></i>
                  <a [routerLink]="item.url" (click)="scrollToTop(); closeMenu()" [innerHTML]="item.label"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>
                </div>
              </li>
            </ul>
          </li>
          <!-- resources -->
          <li class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
            <a class="dropdown-toggle" (click)="toggleDropdown($event)">Resources</a>
            <ul *ngIf="isDropdownOpen">
              <li *ngFor="let item of resourceItems; let i = index" class="dropdown-item" [routerLink]="item.url"
                [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'"
                (click)="scrollToTop(); closeMenu()">
                <div class="item-row">
                  <i [class]="item.icon"></i>
                  <a [routerLink]="item.url" (click)="scrollToTop(); closeMenu()" [innerHTML]="item.label"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>
                </div>
              </li>
            </ul>
          </li>
          <li><a class="nav-link scrollto" (click)="scrollToTop(); closeMenu()" routerLink="contact"
              routerLinkActive="active">Contact</a></li>
          <!-- login -->
          <li *ngIf="!isLoginDisabled" class="dropdown" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
            <a class="dropdown-toggle" (click)="toggleDropdown($event)">Login</a>
            <ul *ngIf="isDropdownOpen">
              <li *ngFor="let item of dropdownItems; let i = index" class="dropdown-item" [routerLink]="item.url"
                [ngStyle]="{'animation-delay': (i * 0.2) + 's'}" [routerLinkActive]="'active'"
                (click)="scrollToTop(); closeMenu()">
                <div class="item-row">
                  <i [class]="item.icon"></i>
                  <a [routerLink]="item.url" (click)="scrollToTop(); closeMenu()" [innerHTML]="item.label"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>
                </div>
              </li>
            </ul>
          </li>
          <!--  -->
        </ul>
        <i class="bi mobile-nav-toggle" [ngClass]="{'bi-x': isMenuOpen, 'bi-list': !isMenuOpen}"
          (click)="toggleMenu()"></i>
      </nav>
      <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-list': !isMenuOpen}" (click)="toggleMenu()"></i>
    </nav>
    <div class="abc">
      <button><a routerLink="/contact" (click)="scrollToTop()" class="btn-getstarted">Talk To Sales</a></button>
      <button><a routerLink="/contact" (click)="scrollToTop()" class="btn-getstarted">Try For Demo</a></button>
    </div>
  </div>
</header>
<!--  -->