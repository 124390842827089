<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../assets/products/rfx-master.png);">
    <div class="container">
        <h1>RFX Master - RFI/RFP/RFQ</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">RFX Master</li>
            </ol>
        </nav>
    </div>
</div>
<!-- Understanding eRFX: RFI, RFP, and RFQ -->
<section class="section-services">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Understanding eRFX: RFI, RFP, and RFQ</h2>
                    <p class="description">In the world of procurement, the eRFX process is essential for streamlining
                        supplier
                        interactions, gathering information, and making informed purchasing decisions. eRFX is a
                        collective term that includes electronic versions of RFI (Request for Information), RFP (Request
                        for Proposal), and RFQ (Request for Quotation). Each of these tools plays a crucial role in the
                        procurement cycle, helping organizations achieve their strategic goals more efficiently.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let service of services" class="col-md-6 col-lg-4" data-aos="zoom-in-up">
                <div class="single-service">
                    <div class="content">
                        <h3 class="title">{{ service.title }}</h3>
                        <p class="description">{{ service.description }}</p>
                        <div [innerHTML]="service.details" class="para"></div>
                    </div>
                    <span class="circle-before"></span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- benefits -->
<section id="whyUs" class="whyUs section">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Benefits of Using eRFX in Procurement</h2>
                    <p class="description">The eRFX process brings several advantages to procurement operations,
                        including:</p>
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div class="col-xl-3 col-md-6 d-flex" *ngFor="let service of WhyUs" [attr.data-aos-delay]="service.aosDelay"
                data-aos="fade-up">
                <div class="service-item position-relative">
                    <div class="items" style="display: flex;align-items:center;">
                        <div class="icon"><i [class]="service.icon + ' icon'"></i></div>
                        <h4><a class="stretched-link text-decoration-none">{{ service.title }}</a></h4>
                    </div>
                    <p>{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>