<footer id="footer" class="footer">
  <div class="container footer-top">
    <div class="row gy-4">
      <div class="col-lg-4 col-md-6 footer-about">
        <a href="index.html" class="d-flex align-items-center new">
          <span class="sitename"><img src="../../assets/home/logo_transparent.png" /></span>
        </a>
        <div class="footer-contact pt-3">
          <p>Near Barcode ,Parkland, <br>Moshi, Pune Maharashtra,<br>India - 412105</p>
          <p> <strong>Phone : </strong> <a target="_blank" href="tel:9209171373" class="phone text-decoration-none">+91
              9209171373</a></p>
          <p> <strong>Email : </strong><a target="_blank" href="mailto:sales@procuremasster.com"
              class="phone text-decoration-none">sales@procuremasster.com </a><br></p>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 footer-links" (click)="scrollToTop()">
        <h4>Login</h4>
        <ul>
          <li><i class="bi bi-chevron-right"></i> <a href="#">Associate</a></li>
          <li><i class="bi bi-chevron-right"></i> <a href="#">Supplier</a></li>
          <li><i class="bi bi-chevron-right"></i> <a href="#">Buyer</a></li>
        </ul>
      </div>

      <div class="col-lg-2 col-md-3 footer-links" (click)="scrollToTop()">
        <h4>Quick Links</h4>
        <ul>
          <li><i class="bi bi-chevron-right"></i> <a routerLink="/about">About</a></li>
          <li><i class="bi bi-chevron-right"></i> <a routerLink="/rfx-master">Products</a></li>
          <li><i class="bi bi-chevron-right"></i> <a routerLink="/blog">Resources</a></li>
          <li><i class="bi bi-chevron-right"></i> <a routerLink="/faqs">FAQs</a></li>
          <li><i class="bi bi-chevron-right"></i> <a routerLink="/contact">Contact</a></li>
        </ul>
      </div>

      <div class="col-lg-4 col-md-12">
        <h4>Follow Us</h4>
        <p>Procure Master is a dynamic platform crafted to support manufacturing businesses of every scale. Its
          easy-to-use interface enables suppliers and buyers to connect seamlessly, collaborate effectively, and build a
          thriving network that promotes growth and achievement. </p>
        <div class="social-links d-flex">
          <a target="_blank" href="https://x.com/procuremasster"><i class="bi bi-twitter-x"></i></a>
          <a target="_blank" href=""><i class="bi bi-facebook"></i></a>
          <a target="_blank" href=""><i class="bi bi-instagram"></i></a>
          <a target="_blank" href="https://www.linkedin.com/in/procure-masster-47350a325/"><i
              class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright text-center">
    <hr>
    <p>© <span>Copyright</span><a href="https://techcognicsindia.com/" class="text-decoration-none text"><strong
          class="px-1 sitename">techcognicsindia.com</strong></a><span>All Rights Reserved</span></p>
    <div class="credits">
      Designed by <a href="https://techcognicsindia.com" class="text-decoration-none text">TechCognics India
        Private Limited</a>
    </div>
  </div>
</footer>
<a id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center cursor-pointer" (click)="scrollToTop()"><i
    class="bi bi-chevron-double-up"></i></a>