import { Component } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './rfx-master.component.html',
  styleUrls: ['./rfx-master.component.scss']
})
export class RfxmasterComponent {

  services = [
    {
      title: 'Request for Information (RFI)',
      details: `
        <strong>Purpose:</strong> To collect general information and understand the market landscape.<br><br>
        <strong>Usage:</strong> Commonly used in the early stages of procurement when the buyer is still exploring options.<br><br>
        <strong>Outcome:</strong> The information gathered from RFIs helps in shortlisting vendors for the next stage of the procurement process, such as an RFP or RFQ.`,
      description: 'An RFI is typically the first step in the eRFX process. It is used when an organization needs to gather general information about potential suppliers or their products and services. The purpose of an RFI is to gain a better understanding of what the market has to offer, identify potential vendors, and gather data that will inform future procurement decisions.'
    },
    {
      title: 'Request for Proposal (RFP)',
      details: `
      <strong>Purpose:</strong>To solicit detailed proposals that outline how a vendor will address the buyer's needs.<br><br>
      <strong>Usage:</strong>Used when the buyer requires specific solutions or services and is evaluating different approaches.<br><br>
      <strong>Outcome:</strong>The RFP process leads to the selection of the most suitable vendor based on a combination of factors such as cost, quality, and technical capability.`,
      description: 'An RFP is used when the buying organization has a clear understanding of its needs but is looking for a detailed proposal on how these needs can be met. This stage involves a more in-depth analysis and typically includes not just pricing but also details on the approach, methodology, and value-added services the vendor can offer.'
    },
    {
      title: 'Request for Quotation (RFQ) ',
      details: `
      <strong>Purpose:</strong>To obtain specific price quotes from vendors for well-defined products or services.<br><br>
      <strong>Usage:</strong>: Best suited for procurements where the specifications are clear and the primary variable is price.<br><br>
      <strong>Outcome:</strong> The RFQ results in the selection of a vendor based primarily on cost, assuming all other factors are equal.`,
      description: 'An RFQ is used when the buying organization knows exactly what it needs and is looking for specific price quotations. This is a more straightforward process compared to an RFP, focusing primarily on pricing and delivery terms. RFQs are commonly used for standard, commoditized products or services where the requirements are clear and unambiguous.'
    }
  ];

  WhyUs = [
    {
      title: 'Efficiency',
      icon: 'bx bxs-bar-chart-square',
      description: `Automation and digitalization of the RFI, RFP, and RFQ processes reduce manual effort and speed up the procurement cycle.`,
      aosDelay: 100
    },
    {
      title: 'Transparency',
      icon: 'bx bxs-file-find',
      description: `A structured approach ensures that all suppliers are evaluated based on the same criteria, fostering fairness and transparency.`,
      aosDelay: 200
    },
    {
      title: 'Data-Driven Decisions',
      icon: 'bx bxs-data',
      description: `eRFX tools provide valuable data that can be analyzed to make informed procurement decisions.`,
      aosDelay: 300
    },
    {
      title: 'Cost Savings',
      icon: 'bi bi-currency-exchange',
      description: `By enabling competitive bidding and precise evaluations, eRFX often leads to better pricing and terms, contributing to overall cost savings.`,
      aosDelay: 400
    },
  ];

}
