<div class="page-title dark-background" data-aos="fade" style="background-image: url(../../assets/Blog/Blog-hero.jpg);">
    <div class="container">
        <h1>Blog</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Blog</li>
            </ol>
        </nav>
    </div>
</div>
<!--  -->
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <section id="blog-posts" class="blog-posts section">
                    <div class="container">
                        <div class="row gy-4">
                            <div *ngFor="let post of posts; let i = index" class="col-12">
                                <article>
                                    <div class="post-img">
                                        <img [src]="post.image" alt="" class="img-fluid" />
                                    </div>
                                    <h1 class="title">{{ post.title }}</h1>
                                    <div class="meta-top">
                                        <ul>
                                            <li class="d-flex align-items-center">
                                                <i class="bi bi-clock"></i>
                                                <time [attr.dateTime]="post.date">{{ post.date }}</time>
                                            </li>
                                        </ul>
                                    </div>
                                    <p class="post-Para">{{ post.content }}</p>
                                    <div id="Content-blog">
                                        <div *ngFor="let section of contentArray" id="page_content">
                                            <div class="pageText">
                                                <h1>{{ section.title }}</h1>
                                                <p class="description post-Para" [innerHTML]="section.paragraphs"></p>
                                                <p class="post-Para">{{section.details}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="col-lg-4 sidebar">
                <app-category></app-category>
            </div>
        </div>
    </div>
</section>
<!--  -->