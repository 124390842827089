import { Component } from '@angular/core';

@Component({
  selector: 'app-part-master',
  templateUrl: './part-master.component.html',
  styleUrls: ['./part-master.component.scss']
})
export class PartMasterComponent {

    features = [
      {
        id: 1,
        title: 'New Part Development',
        description: `New Part Development is a critical process in the procurement cycle, especially when dealing with the introduction of new products or components that have no existing suppliers. Our platform facilitates this process by providing a structured framework that allows for the precise definition of specifications, seamless communication with potential suppliers, and efficient project management from inception to completion.`,
        image: '../../../assets/products/new-development.png',
        aos: 'fade-right',
        details: `
          <i class="bi bi-award icon-spacing"></i><strong> Specification Management:</strong> Easily define and manage detailed specifications for new parts, ensuring all stakeholders have access to the latest requirements.<br><br>
          <i class="bi bi-people icon-spacing"></i><strong> Supplier Collaboration:</strong> Engage with suppliers through a collaborative portal where they can submit prototypes, provide feedback, and address any queries in real-time.<br><br>
          <i class="bi bi-graph-up icon-spacing"></i><strong> Project Tracking:</strong> Monitor the progress of new part development with Gantt charts, milestone tracking, and automated alerts, ensuring the project stays on schedule.<br><br>
          <i class="bi bi-shield-check icon-spacing"></i><strong> Quality Assurance:</strong> Implement rigorous quality checks and testing protocols, all documented and tracked within the platform, to ensure that the new part meets all necessary standards.`,
      },
      {
        id: 2,
        title: 'Localization',
        description: `Localization involves the adaptation of products or parts to meet the specific requirements of different regions or markets. This process is crucial for reducing costs, complying with local regulations, and improving supply chain resilience. Our platform simplifies localization by providing tools that help identify local suppliers, manage compliance requirements, and optimize part design for local production.`,
        image: '../../../assets/products/localization.png',
        aos: 'fade-left',
        details: `
          <i class="bi bi-search icon-spacing"></i><strong> Supplier Identification:</strong> Use our advanced search and filtering tools to find and evaluate local suppliers who meet your specific criteria, ensuring you partner with the best fit for your needs.<br><br>
          <i class="bi bi-exclamation-circle icon-spacing"></i><strong> Regulatory Compliance:</strong> Keep track of local regulations and standards that might affect part design or production. Our platform offers integration with compliance databases and provides alerts for any changes in regulatory requirements.<br><br>
          <i class="bi bi-cash icon-spacing"></i><strong> Cost Optimization:</strong> Leverage cost analysis tools to compare the economics of local versus global sourcing, helping you make informed decisions that balance cost, quality, and lead time.<br><br>
          <i class="bi bi-pencil icon-spacing"></i><strong> Design Adaptation:</strong> Collaborate with local engineers and designers to modify part designs for local manufacturing capabilities, ensuring that your parts are not only compliant but also optimized for local production.`,
      }
    ];
  }
  