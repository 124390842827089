import { Component } from '@angular/core';

@Component({
  selector: 'app-e-auction-pro',
  templateUrl: './e-auction-pro.component.html',
  styleUrls: ['./e-auction-pro.component.scss']
})
export class EAuctionProComponent {

  features = [
    {
      icon: 'bx bxs-time',
      title: 'Real-Time Bidding',
      description: `Suppliers compete in a dynamic, real-time environment, driving down prices and ensuring that buyers achieve the most cost-effective procurement outcomes.`,
      delay: 100
    },
    {
      icon: 'bx bxs-file-find',
      title: 'Transparent Process',
      description: `The eAuction platform ensures full transparency. All participants can see the bidding activity, which fosters a fair competitive environment and reduces the potential for disputes.`,
      delay: 200
    },
    {
      icon: 'bx bxs-book-content',
      title: 'Multiple Auction Formats',
      description: `The platform supports various auction types, including reverse auctions, Dutch auctions, sealed-bid auctions, and forward auctions, allowing procurement teams to choose the format that best suits their specific needs.`,
      delay: 300
    },
    {
      icon: 'bi bi-globe-central-south-asia',
      title: 'Global Reach',
      description: `By bringing suppliers from around the world into one platform, eAuction expands the pool of potential vendors, increasing competition and enabling organizations to access the best products and services at the lowest prices.`,
      delay: 400
    },
    {
      icon: 'bi bi-database-fill-add',
      title: 'Data-Driven Decisions',
      description: `The platform provides analytics and reports that help procurement professionals analyze bidding patterns, supplier performance, and market trends, leading to more informed decision-making.`,
      delay: 500
    },
    {
      icon: 'bi bi-stack-overflow',
      title: 'Integration with Procurement Workflow',
      description: 'eAuction seamlessly integrates with other modules like eRFX, New Vendor Development, and New Part Development. This integration ensures that the auction process is part of a cohesive procurement strategy, aligning with organizational goals.',
      delay: 600
    }
  ];

  WhyUs = [
    {
      title: 'Reverse Auction',
      icon: 'bx bx-transfer-alt',
      aosDelay: 100,
      details: `
      <strong>Description:</strong> In a reverse auction, suppliers compete to offer the lowest price for goods or services. The price typically decreases over time as suppliers underbid each other.<br><br>
      <strong>Use Case:</strong> Ideal for procuring commodities or standardized products where price competition is the main factor.<br>`,
    },
    {
      title: 'Dutch Auction',
      icon: 'bx bxs-bar-chart-alt-2',
      aosDelay: 200,
      details: `
      <strong>Description:</strong> A Dutch auction starts with a high price that decreases over time until a supplier accepts the current price. This type of auction is less common but useful in scenarios where speed is crucial.<br><br>
      <strong>Use Case:</strong> Suitable for situations where the buyer wants to procure goods quickly and is willing to accept the first acceptable bid.<br>`,
    },
    {
      title: 'English Auction',
      icon: 'bx bx-signal-5',
      aosDelay: 300,
      details: `
      <strong>Description:</strong> The most traditional type of auction, where bids increase over time, and the highest bid wins. While more common in asset sales, it can be used in procurement for unique or rare items.<br><br>
      <strong>Use Case:</strong> Used when the buyer is interested in acquiring a unique product or service, and the value is driven by market demand.<br>`,
    },
    {
      title: 'Forward Auction',
      icon: 'bx bxs-fast-forward-circle',
      aosDelay: 400,
      details: `
      <strong>Description:</strong> In a forward auction, buyers compete to purchase goods or services by placing higher and higher bids. This type of auction is commonly used in sales scenarios where the seller seeks to maximize the selling price.<br><br>
      <strong>Use Case:</strong> Ideal for situations where a company wants to sell surplus inventory, obsolete equipment, or other assets. The highest bidder wins the item, making it suitable for maximizing revenue on saleable items.<br>`,
    }
  ];

}

