import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RfxmasterComponent } from './rfx-master/rfx-master.component';
import { EAuctionProComponent } from './e-auction-pro/e-auction-pro.component';
import { NewVendorComponent } from './new-vendor/new-vendor.component';
import { PartMasterComponent } from './part-master/part-master.component';
import { SourceMatrixComponent } from './source-matrix/source-matrix.component';

@NgModule({
    declarations: [RfxmasterComponent, EAuctionProComponent, NewVendorComponent, PartMasterComponent,SourceMatrixComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'rfx-master',
                component: RfxmasterComponent,
            },
            {
                path: 'eAuction-Pro',
                component: EAuctionProComponent,
            },
            {
                path: 'new-vendor',
                component: NewVendorComponent,
            },
            {
                path: 'part-master',
                component: PartMasterComponent,
            },
            {
                path: 'source-matrix',
                component: SourceMatrixComponent,
            },
        ]),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductModule { }
