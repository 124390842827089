import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as AOS from 'aos';

interface Feature {
  id: number;
  title: string;
  description: string;
  Icon: string;
  color: string;
  delay: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  count: number = 0;
  accuratecount: number = 0;
  revenuecount: number = 0;
  counter: number = 0;

  private countstop!: any;
  private accuratecountstop!: any;
  private revenuecountstop!: any;
  private counterstop!: any;

  @ViewChild('statsSection', { static: false }) statsSection!: ElementRef;

  ngAfterViewInit(): void {
    AOS.init(
      {
        offset: 120,
        duration: 700,
      }
    );

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startCounters();
          observer.unobserve(this.statsSection.nativeElement);
        }
      });
    }, { threshold: 0.5 });

    observer.observe(this.statsSection.nativeElement);
  }

  constructor(private elementRef: ElementRef) { }

  flippedCard: number | null = null;

  handleMouseEnter(id: number): void {
    this.flippedCard = id;
  }

  handleMouseLeave(): void {
    this.flippedCard = null;
  }

  //our Deliverables
  startCounters(): void {
    this.countstop = window.setInterval(() => {
      this.count++;
      if (this.count >= 100) {
        clearInterval(this.countstop);
      }
    }, 50);

    this.accuratecountstop = window.setInterval(() => {
      this.accuratecount++;
      if (this.accuratecount >= 1000) {
        clearInterval(this.accuratecountstop);
      }
    }, 0);

    this.revenuecountstop = window.setInterval(() => {
      this.revenuecount++;
      if (this.revenuecount >= 100) {
        clearInterval(this.revenuecountstop);
      }
    }, 50);

    this.counterstop = window.setInterval(() => {
      this.counter += 600000;
      if (this.counter >= 15000000) {
        this.counter = 15000000;
        clearInterval(this.counterstop);
      }
    }, 150);
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  additionalParagraphs: string[] = [
    `At Procure Masster, we simplify the procurement process, making it easier than ever to achieve success. Our platform connects buyers and suppliers digitally, streamlining the way you handle eRFx, eAuctions, and vendor management. Whether you are seeking new vendors or developing new parts, Procure Masster is your go-to solution for efficient and effective procurement.`,
    `Join us and experience a smarter way to connect, negotiate, and grow your business. Welcome to the future of procurement – welcome to Procure Masster!`
  ];

  exploreFeatures: Feature[] = [
    { id: 1, title: 'Online RFQ Management', description: `Simplify request for quotes with an intuitive, user-friendly interface.`, Icon: 'bx bxs-credit-card-front', color: '#56019C', delay: 100 },
    { id: 2, title: 'eAuction Capabilities', description: `Engage suppliers in competitive bidding for the best deals.`, Icon: 'bx bxs-calendar-week', color: '#56019C', delay: 200 },  
    { id: 3, title: 'Get Your New vendor', description: `Effortlessly onboard new vendors and expand your supply chain.`, Icon: 'bx bxs-message-check', color: '#56019C', delay: 500 },
    { id: 4, title: 'Advanced Spend Analysis', description: `Track, analyze, and optimize your procurement spend effectively.`, Icon: 'bi-bar-chart-line-fill', color: '#56019C', delay: 400 },
    { id: 5, title: 'Strategic Sourcing', description: `Leverage our strategic sourcing expertise to optimize your procurement strategies and drive profitability.`, Icon: 'bi-sourceforge', color: '#56019C', delay: 500 },
    { id: 6, title: 'Expert Support', description: `An industrial expert with over 30 years in Supplier Quality Assurance and as a Technical Advisor for 'first-time-right' RFQs.`, Icon: 'bi-globe-central-south-asia', color: '#56019C', delay: 600 },
   
  ];

  clients = [
    { name: 'Client 1', logo: '../../assets/clients/thermal.png' },
    { name: 'Client 2', logo: '../../assets/clients/Mechtron .png' },
    { name: 'Client 3', logo: '../../assets/clients/filter.png' },
    { name: 'Client 4', logo: '../../assets/clients/ofentech.png' },
  ];

  Aboutfeatures = [
    { id: 1, title1: `Seamless Digitization for Efficiency: `, icon: 'bx bxs-bar-chart-square', title: `Our platform streamlines the procurement process with cutting-edge tools such as Online RFQs, eAuctions, and New Vendor Registration. Say goodbye to paperwork and hello to an efficient, transparent process that saves time and money.`, delay: 100 },
    { id: 2, title1: `Strategic Spend Analysis: `, title: `Gain valuable insights into your spending patterns with our comprehensive Spend Analysis features. Identify opportunities for cost savings, optimize supplier relationships, and make informed strategic decisions that drive profitability.`, delay: 200 },
    { id: 3, title1: `Future-Ready Solutions: `, title: `Embrace the future of procurement with our innovative digital solutions. Stay ahead of the curve with technologies that evolve with industry trends and enhance your procurement capabilities.`, delay: 300 },
    { id: 4, title1: `Decades of Expertise:`, title: `Founded by industry leaders with over 20 years of experience in Strategic Sourcing and Supply Chain Management, our team brings unparalleled knowledge and innovative strategies to your organization.`, delay: 400 },
    { id: 5, title1: `Accelerated Results: `, title: `Our advanced procurement tools are designed to speed up the procurement cycle, enabling you to achieve faster, more effective results. Whether you’re sourcing new suppliers or managing existing contracts, our platform supports your goals with precision and agility.`, delay: 500 },
    { id: 6, title1: `Empowering Your Success: `, title: `We see procurement as a driver of success, not just a necessity. Our solutions are crafted to help you turn procurement challenges into opportunities for growth and success.`, delay: 600 },
  ];

  ngOnInit(): void { }
}


