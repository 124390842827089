import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent {
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  portfolio = [
    {
      id: 1,
      imageUrl: '../../assets/Blog/buyers-suppliers.jpg',
      desc: 'Navigating eRFx Processes: Best Practices for Buyers and Suppliers.',
      link: '/navigating-eRFx-processes',
    },
    {
      id: 2,
      imageUrl: '../../assets/Blog/technology.jpg',
      desc: 'The Future of eAuctions: Leveraging Technology for Better Deals.',
      link: '/future-of-eAuctions',
    },
    {
      id: 2,
      imageUrl: '../../assets/Blog/buyer-supplier-reln.jpg',
      desc: 'Building Strong Buyer-Supplier Relationships in a Digital World.',
      link: '/buyer-supplier-relationship',
    },
  ];

}
