<section class="testimonial text-center">
    <div class="container section-title heading" data-aos="fade-up">
        <h2>Customer Testimonial</h2>
    </div>
    <div class="container">
        <div id="testimonial4" class="carousel slide testimonial4_control_button thumb_scroll_x swipe_x"
            data-bs-ride="carousel" data-bs-pause="hover" data-bs-interval="3000" data-bs-duration="2000">

            <ol class="carousel-indicators">
                <li *ngFor="let testimonial of testimonials; let i = index" [attr.data-bs-target]="'#testimonial4'"
                    [attr.data-bs-slide-to]="i" [ngClass]="{'active': i === 0}">
                </li>
            </ol>

            <div class="carousel-inner" role="listbox">
                <div *ngFor="let testimonial of testimonials; let i = index"
                    [ngClass]="{'carousel-item': true, 'active': i === 0}">
                    <div class="testimonial4_slide">
                        <i class='bx bxs-quote-alt-left'></i>
                        <div>
                            <p>{{testimonial.text}}</p>
                            <h4>- {{testimonial.client}}</h4>
                        </div>
                        <i class='bx bxs-quote-alt-right'></i>
                    </div>
                </div>
            </div>

            <a class="carousel-control-prev text-decoration-none" href="#testimonial4" role="button"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next text-decoration-none" href="#testimonial4" role="button"
                data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
            </a>
        </div>
    </div>
</section>