<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
  style="background-image: url(../../assets/about/about-page-title-bg.jpg);">
  <div class="container">
    <h1>About</h1>
    <nav class="breadcrumbs">
      <ol>
        <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
        <li class="current">About</li>
      </ol>
    </nav>
  </div>
</div>
<!-- about procureMasster -->
<section id="features" class="features section light-background">
  <div class="container section-title" data-aos="fade-up">
    <h2>Excellence in Procurement</h2>
  </div>
  <div class="container">
    <div class="row gy-4 justify-content-between features-item">
      <div class="col-lg-12 col-md-12 d-flex align-items-center" data-aos="fade-right" data-aos-delay="200">
        <div class="content">
          <div *ngFor="let paragraph of additionalParagraphs">
            <p>{{ paragraph }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- about vision,mission and story -->
<section id="about" class="about section" style="background: #f4f4f4;">
  <div class="container section-title" data-aos="fade-up" style="padding-bottom: 0px;">
    <h2>About Us</h2>
  </div>
  <div class="container">
    <div class="row no-gutters">
      <div class="content col-lg-5 d-flex align-items-stretch">
        <div class="content">
          <img src="../../assets/about/16.png" class="img-fluid img3" alt="">
        </div>
      </div>
      <div class="col-lg-7 d-flex align-items-stretch">
        <div class="icon-boxes d-flex flex-column justify-content-center">
          <div class="row">
            <div class="col-md-12 icon-box" *ngFor="let box of iconBoxes" [attr.data-aos-delay]="box.delay"
              data-aos="fade-up">
              <div style="align-items: center;display:flex;">
                <i [class]="box.icon"></i>
                <h4>{{ box.title }}</h4>
              </div>
              <p>{{ box.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- our values -->
<section id="value" class="value section">
  <div class="container section-title" data-aos="fade-up">
    <h2>Our Values</h2>
  </div>
  <div class="value-image" data-aos="zoom-in-up">
    <img src="../../assets/about/core-values.png" alt="Centered Image" />
  </div>
</section>
<!-- Why Choose Us -->
<section id="whyUs" class="whyUs section" style="background: #f4f4f4;">
  <div class="container section-title" data-aos="fade-up">
    <h2>Why Choose Us ?</h2>
  </div>
  <div class="container">
    <div class="row gy-4">
      <div class="col-xl-3 col-md-6 d-flex" *ngFor="let service of WhyUs" [attr.data-aos-delay]="service.aosDelay"
        data-aos="fade-up">
        <div class="service-item position-relative">
          <div class="items" style="display: flex;align-items:center;">
            <div class="icon"><i [class]="service.icon + ' icon'"></i></div>
            <h4><a class="stretched-link text-decoration-none">{{ service.title }}</a></h4>
          </div>
          <p>{{ service.description }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- join us -->
<section id="joinUs" class="joinUs section">
  <img src="../../assets/about/join-us.png" alt="about-us">
  <div class="container section-title" data-aos="fade-up">
    <h2 data-aos="fade-in" style="background: #d0e9ff;">Join Us on Our Journey</h2>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pt-3 pt-lg-0 content" data-aos="zoom-in">
        <p>Whether you’re a small business looking to streamline your procurement process or a large enterprise seeking
          to optimize your supply chain, Procure Masster is here to help. Join us on our journey to create a more
          efficient, transparent, and innovative procurement ecosystem.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- team -->
<section id="team" class="team section">
  <div class="container section-title" data-aos="fade-up">
    <h2>Our Team</h2>
    <p class="teamText">At Procure Masster , our success is driven by a dedicated and diverse team of professionals who
      are passionate
      about innovation in procurement. Our leadership and expert team members bring a wealth of experience, knowledge,
      and a shared commitment to delivering exceptional value to our users. Get to know the people behind our platform:
    </p>
  </div>
  <div class="container">
    <div class="row gy-4">
      <div class="col-lg-6" *ngFor="let member of teamMembers" [attr.data-aos]="'fade-up'"
        [attr.data-aos-delay]="member.aosDelay">
        <div class="team-member align-items-start">
          <div style="align-items: center;display:flex;">
            <!-- <div class="pic">
              <img [src]="member.imgSrc" class="img-fluid" alt="team">
            </div> -->
            <div class="pos">
              <h4>{{ member.name }}</h4>
              <span>{{ member.position }}</span>
            </div>
          </div>
          <div class="member-info">
            <p>{{ member.description }}</p>
            <p>{{ member.description1 }}</p>
            <p>{{ member.desc3 }}</p>
            <div class="social">
              <a href="{{ member.social.twitter }}"><i class="bi bi-twitter-x"></i></a>
              <a href="{{ member.social.facebook }}"><i class="bi bi-facebook"></i></a>
              <a href="{{ member.social.instagram }}"><i class="bi bi-instagram"></i></a>
              <a href="{{ member.social.linkedin }}"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--  -->