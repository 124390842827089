import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BlogsComponent } from './blogs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Blog1Component } from '../blog-details/blog1/blog1.component';
import { CategoryComponent } from '../blog-details/category/category.component';
import { Blog2Component } from '../blog-details/blog2/blog2.component';
import { Blog3Component } from '../blog-details/blog3/blog3.component';

@NgModule({
    declarations: [BlogsComponent, Blog1Component, CategoryComponent, Blog2Component,Blog3Component],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: 'blog',
                component: BlogsComponent,
            },
            {
                path: 'navigating-eRFx-processes',
                component: Blog1Component,
            },
            {
                path: 'future-of-eAuctions',
                component: Blog2Component,
            },
            {
                path: 'buyer-supplier-relationship',
                component: Blog3Component,
            },
        ]),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BlogModule { }
