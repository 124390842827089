import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  constructor(private router: Router) { }
  faqs = [
    {
      question: 'What is Procure Masster and how does it work?',
      description: [`Procure Masster is a comprehensive web-based procurement platform designed to streamline the sourcing and purchasing process for businesses of all sizes. It allows organizations to manage suppliers, conduct e-sourcing events, and automate procurement workflows all in one place. Users can create requests for quotations (RFQs), evaluate bids, manage contracts, and track order fulfilment seamlessly through the platform.`],
      isActive: false,
    },
    {
      question: 'How do I create an account on Procure Masster?',
      answer: '',
      description: [
        `To create an account, click on the <a href="#" class="register-link">"Register"</a> button on the homepage. You will need to provide basic information such as your name, email address, company name, and contact details. After submitting the form, you will receive a verification email. Follow the instructions in the email to verify your account and set up your password.`
      ],
      isActive: false
    },
    {
      question: 'What types of sourcing events can I manage on Procure Masster?',
      answer: `Procure Masster supports various types of sourcing events, including RFIs, RFQs, RFPs, eAuctions, and more.`,
      isActive: false,
      description: [
        '<b>Requests for Information (RFI):</b> Gather information from potential suppliers.',
        '<b>Requests for Quotation (RFQ):</b> Invite suppliers to submit pricing proposals.',
        '<b>Requests for Proposal (RFP):</b> Solicit detailed proposals from suppliers.',
        '<b>eAuction:</b> Suppliers bid against each other in real-time to offer the best price.',
        '<b>Get Your New Vendor:</b> Help to identify the new Vendor for your requirement.',
        '<b>NPD:</b> Help to develop new parts and follow the NPD process through the platform.',
        '<b>Strategic Sourcing Service:</b> Analyze your spend, identify opportunities for savings, market insights, and a roadmap for procurement.'
      ]
    },
    {
      question: 'How do I invite suppliers to participate in sourcing events',
      description: [`After creating a sourcing event, you can invite suppliers directly through the platform by entering their email addresses or selecting them from your pre-existing supplier database. Suppliers will receive an email invitation with a link to participate in the event. You can also set deadlines and communicate directly with suppliers through the platform's messaging feature.`],
      isActive: false
    },
    {
      question: 'Is my data secure on Procure Masster?',
      description: ['Absolutely. Data security is our top priority. Procure Masster uses state-of-the-art encryption, secure data centers. We also provide role-based access control, so you can manage who has access to sensitive information within your organization.'],
      isActive: false
    },
    {
      question: 'How can I evaluate and compare supplier bids on Procure Masster?',
      description: [`Once all bids are submitted, Procure Masster provides you with advanced tools to evaluate and compare them. You can use criteria such as price, delivery time, quality, and supplier ratings to make informed decisions. The platform also offers analytics and reporting features to help you visualize and assess the data more effectively.`],
      isActive: false
    },
    {
      question: 'What kind of reports can I generate?',
      answer: `Procure Masster allows you to generate a wide range of reports, including:`,
      description: [
        '<b>Spend Analysis Reports:</b> Understand spending patterns across different categories and suppliers.',
        '<b>RFx /eAuction event Analysis report:</b> Get detailed insights into the outcome of RFx, eAuction events, including bid comparisons and savings achieved.',
        '<b>Event Summary Reports:</b> : Get detailed insights into the outcome of sourcing events, including bid comparisons and savings achieved.',
      ],
      isActive: false
    },
    {
      question: 'What support options are available if I encounter issues?',
      answer: `We offer multiple support options to assist you:`,
      description: [
        '<b>Knowledge Base:</b> Access a comprehensive library of articles, tutorials, and how-to guides.',
        `<b>Live Chat:</b> Get real-time assistance from our support team during business hours.`,
        `<b>Email Support:</b> :Reach out to us at <a href="mailto:sales@procuremasster.com">sales@procuremasster.com</a> for help with more complex issues.`,
        '<b>Phone Support:</b> : Call us at for immediate assistance.',
      ],
      isActive: false
    },
    {
      question: 'How do I customize the platform to fit my procurement processes?',
      description: [`Procure Masster is highly customizable, allowing you to tailor modules /product Combinations and user interfaces to match your specific procurement practices. Our implementation team is also available to assist with more complex customizations.`],
      isActive: false
    },
    {
      question: 'What are the payment options for using Procure Masster?',
      description: [`Procurer Masster offers flexible payment options, including monthly, event based or annual subscriptions. For larger organizations, custom pricing plans are available based on the specific needs and usage volume. Please contact our sales team for more details on pricing and payment options.`],
      isActive: false
    },
    {
      question: 'How does Procure Masster help in achieving cost savings?',
      description: [`Procure Masster helps you achieve cost savings through competitive bidding, supplier negotiation tools, and spend analysis reports. By consolidating procurement activities on a single platform, you can also reduce administrative costs and increase efficiency. Additionally, our reverse auction feature often results in lower prices as suppliers compete to offer the best value.`],
      isActive: false
    },
    {
      question: 'Can I access Procure Masster on mobile devices?',
      description: [`Yes, Procure Masster is fully mobile-responsive and can be accessed on any smartphone or tablet through your web browser.`],
      isActive: false
    },
  ];

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleFaq(index: number) {
    this.faqs[index].isActive = !this.faqs[index].isActive;
    this.faqs.forEach((faq, i) => {
      if (i !== index) {
        faq.isActive = false;
      }
    });
  }

  navigateToContact() {
    this.router.navigate(['/contact']);
  }

  handleClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('register-link')) {
      event.preventDefault();
      this.navigateToContact();
    }
  }

}
