<!-- hero section -->
<section id="hero" class="hero section dark-background">
  <div class="container">
    <div class="row gy-4">
      <div class="col-lg-5 order-lg-last new-img" data-aos="zoom-out" data-aos-delay="100">
        <img src="../../assets/home/corp-1.png" class="img-fluid animated" alt="">
      </div>
      <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-in">
        <h3 class="display-4">Welcome to <span class="typing-effect"> Procure Masster!</span></h3>
        <h4>Procurement Made Simple – Elevate Your Success</h4>
        <div *ngFor="let paragraph of additionalParagraphs">
          <p>{{ paragraph }}</p>
        </div>
        <div class="d-flex flex-column flex-md-row" data-aos="fade-up" data-aos-delay="200">
          <a routerLink="/contact" class="btn-get-started text-decoration-none" (click)="scrollToTop()">Contact Us<i
              class="bi bi-arrow-right"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Procure Masster ? -->
<section id="features" class="features section light-background">
  <div class="container section-title" data-aos="fade-up">
    <h2>Why Choose Procure Masster ?</h2>
  </div>
  <div class="container">
    <div class="row gy-4 justify-content-between features-item">
      <div class="col-lg-4">
        <img src="../../assets/home/whyus-new.png" class="img-fluid img2" alt="">
      </div>
      <div class="col-lg-8 d-flex align-items-center" data-aos="fade-left" data-aos-delay="100">
        <div class="row align-self-center gy-4 content">
          <div class="col-md-6" *ngFor="let feature of Aboutfeatures" [attr.data-aos-delay]="feature.delay"
            data-aos="fade-up">
            <div class="feature-box d-flex align-items-center">
              <h3><b>{{ feature.title1}}</b>{{ feature.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Explore Our Features -->
<section id="exfeatures" class="exfeatures section">
  <div class="container section-title" data-aos="fade-up">
    <h2>Explore Our Features</h2>
  </div>
  <div class="container">
    <div class="row gy-5">
      <div class="col-lg-7 d-flex order-2 order-xl-1">
        <div class="row align-self-center gy-5">
          <div class="col-md-6 icon-box" *ngFor="let box of exploreFeatures" data-aos="fade-up">
            <i [class]="box.Icon"></i>
            <div>
              <h4>{{ box.title }}</h4>
              <p>{{ box.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 d-flex align-items-center order-1 order-xl-2" data-aos="fade-up">
        <img src="../../assets/home/whyus.png" class="img-fluid new-images" alt="">
      </div>
    </div>
  </div>
</section>
<!-- stats section -->
<section id="stats" class="stats section dark-background" #statsSection>
  <img src="../../assets/home/cta-img.jpg" alt="" data-aos="fade-in">
  <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
    <div class="subheading">
      <h3>What we have achieved so far</h3>
    </div>
    <div class="row gy-4">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="stats-item text-center w-100 h-100">
          <span class="purecounter">{{ count }}+</span>
          <p>Client Success Stories</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stats-item text-center w-100 h-100">
          <span class="purecounter">{{ accuratecount }}+</span>
          <p>Globally Registered Supplier</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stats-item text-center w-100 h-100">
          <span class="purecounter">{{ revenuecount }}+</span>
          <p>Procure Master Verified and Evaluated Supplier</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stats-item text-center w-100 h-100">
          <span class="purecounter">{{ (counter / 10000000).toFixed(2) }} Cr</span>
          <p>Cost Saving Generated through platform</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- our clients -->
<section id="new-clients" class="new-clients section">
  <div class="container section-title" data-aos="fade-up">
    <h2>Our Clients</h2>
  </div>
  <div class="container" data-aos="fade-up">
    <div class="row gy-4">
      <div *ngFor="let client of clients" class="col-lg-3 col-md-3 col-6 client-logo">
        <img [src]="client.logo" class="img-fluid" [alt]="client.name">
      </div>
    </div>
  </div>
</section>
<!-- testimonials -->
<app-testimonials></app-testimonials>