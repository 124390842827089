<div class="widgets-container">
    <!-- Search Widget -->
    <div class="search-widget widget-item">
        <h3 class="widget-title">Search</h3>
        <form (submit)="onSearch()">
            <input type="text" [(ngModel)]="searchTerm" name="search" />
            <button type="submit" title="Search">
                <i class="bi bi-search"></i>
            </button>
        </form>
    </div>

    <!-- Categories Widget -->
    <div class="categories-widget widget-item">
        <h3 class="widget-title">Categories</h3>
        <ul class="mt-3">
            <li *ngFor="let category of categories">
                <a class="text-decoration-none non-clickable">
                    {{ category.name }} <span>({{ category.count }})</span>
                </a>
            </li>
        </ul>
    </div>

    <!-- Recent Posts Widget -->
    <div class="recent-posts-widget widget-item">
        <h3 class="widget-title">Recent Posts</h3>
        <div *ngFor="let post of recentPosts" class="post-item d-flex">
            <img [src]="post.image" alt="" class="flex-shrink-0" />
            <div>
                <h4>
                    <a [routerLink]="post.link" class="text-decoration-none" (click)="scrollToTop()">{{ post.title
                        }}</a>
                </h4>
                <time [attr.dateTime]="post.date">{{ post.date }}</time>
            </div>
        </div>
    </div>
</div>