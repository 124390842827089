<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../../assets/products/part-masster1.png);">
    <div class="container">
        <h1>Part Master – New Part Development</h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Part Master</li>
            </ol>
        </nav>
    </div>
</div>
<!-- key features -->
<section id="features" class="features section-bg">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">New Part Development & Localization on Our Procurement Platform.</h2>
                    <p class="description">In the dynamic world of procurement, innovation and efficiency are paramount.
                        Our platform is meticulously designed to streamline the complex processes of New Part
                        Development and Localization, ensuring seamless integration and rapid time-to-market for new
                        components.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 d-flex" *ngFor="let feature of features" [attr.data-aos]="feature.aos">
                <div class="card">
                    <div class="card-img">
                        <img [src]="feature.image" alt="...">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a>{{feature.title}}</a></h5>
                        <p class="card-text">{{feature.description}}</p>
                        <div>
                            <h1> Key features include:</h1>
                            <p [innerHTML]="feature.details" class="para"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>