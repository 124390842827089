import { Component } from '@angular/core';

@Component({
  selector: 'app-source-matrix',
  templateUrl: './source-matrix.component.html',
  styleUrls: ['./source-matrix.component.scss']
})
export class SourceMatrixComponent {
  services = [
    {
      title: 'Comprehensive Planning',
      description: `We assist in creating detailed project plans, defining scope, objectives, timelines, and resource allocation to ensure that your procurement projects are executed efficiently and effectively.`,
      icon: 'bx bxs-analyse',
      color: '#0dcaf0',
      delay: 100
    },
    {
      title: 'Real-time Collaboration',
      description: `We facilitate real-time collaboration among stakeholders, enabling seamless communication and coordination across teams, suppliers, and other external partners.`,
      icon: 'bx bxs-time',
      color: '#fd7e14',
      delay: 200
    },
    {
      title: 'Progress Tracking',
      description: `Stay on top of your projects with our advanced tracking tools that provide real-time updates on milestones, deliverables, and overall project status, helping you to identify and address potential issues before they escalate.`,
      icon: 'bx bxs-color',
      color: '#20c997',
      delay: 300
    },
    {
      title: 'Risk Management',
      description: `Identify, assess, and mitigate risks associated with your projects through our platform's risk management tools, ensuring that your projects stay on track and within budget.`,
      icon: 'bx bx-street-view',
      color: '#df1529',
      delay: 400
    },
    {
      title: 'Reporting and Analytics',
      description: `Leverage our powerful reporting and analytics capabilities to gain insights into project performance, enabling data-driven decision-making and continuous improvement.`,
      icon: 'bx bxs-report',
      color: '#6610f2',
      delay: 500
    },
    {
      title: 'Integration with Procurement Processes',
      description: `Our project management services are fully integrated with other procurement processes such as eRFX, eAuction, and new vendor and part development, ensuring a seamless experience from project initiation to completion`,
      icon: 'bi bi-stack-overflow',
      color: '#f3268c',
      delay: 600
    }
  ];

  flippedCard: number | null = null;

  handleMouseEnter(id: number): void {
    this.flippedCard = id;
  }

  handleMouseLeave(): void {
    this.flippedCard = null;
  }

  exploreFeatures = [
    { id: 1, title: 'Supplier Market Analysis', description: `We conduct thorough market analysis to identify potential suppliers and assess their capabilities, ensuring that your organization has access to the best options available.`, Icon: 'bx bxs-store-alt', color: '#56019C', delay: 100 },
    { id: 2, title: 'Cost Optimization', description: `We provide service enables detailed cost analysis and benchmarking, helping you to negotiate better terms and achieve cost savings without compromising on quality or service levels.`, Icon: 'bx bxs-bar-chart-alt-2', color: '#56019C', delay: 200 },
    { id: 3, title: 'Risk Management', description: `Strategic sourcing is not just about cost; it's also about minimizing risk. We help you evaluate the financial stability, compliance, and reliability of suppliers to ensure long-term sustainability.`, Icon: 'bx bx-street-view', color: '#56019C', delay: 300 },
    { id: 4, title: 'Supplier Relationship Management', description: `We emphasize building strong, collaborative relationships with key suppliers to drive innovation, efficiency, and continuous improvement in your supply chain.`, Icon: 'bx bx-transfer-alt', color: '#56019C', delay: 400 },
    { id: 5, title: 'Sustainability and CSR', description: `Our strategic sourcing approach incorporates sustainability and corporate social responsibility (CSR) criteria, ensuring that your suppliers align with your organization's values and contribute to a sustainable future.`, Icon: 'bi-sourceforge', color: '#56019C', delay: 500 },
  ];
}

