<!-- hero section -->
<div class="page-title dark-background" data-aos="fade"
    style="background-image: url(../../../assets/products/vendor-3.png);">
    <div class="container">
        <h1>Get Your New Vendor </h1>
        <nav class="breadcrumbs">
            <ol>
                <li><a routerLink="/" class="text-decoration-none text-white">Home</a></li>
                <li class="current">Get Your New Vendor</li>
            </ol>
        </nav>
    </div>
</div>
<!-- key features -->
<section id="whyUs" class="whyUs section" style="background-color: #ececec;">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">New Vendor Development</h2>
                    <p class="description">New Vendor Development is a critical aspect of the procurement process that
                        involves identifying, evaluating, and onboarding new suppliers to ensure a robust and
                        competitive supply chain. In today’s dynamic business environment, it’s essential to continually
                        expand and diversify the vendor base to mitigate risks, drive innovation, and optimize costs.
                </div>
            </div>
        </div>
        <div class="row gy-4">
            <div class="col-xl-3 col-md-6 d-flex" *ngFor="let service of features" [attr.data-aos-delay]="service.delay"
                data-aos="fade-up">
                <div class="service-item position-relative">
                    <div class="items" style="display: flex;align-items:center;">
                        <div class="icon"><i [class]="service.icon + ' icon'"></i></div>
                        <h4><a class="stretched-link text-decoration-none">{{ service.title }}</a></h4>
                    </div>
                    <p>{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- benefits -->
<section id="value" class="value section" style="padding-bottom: 0px;">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
                <div class="header-section" data-aos="fade-up">
                    <h2 class="title">Advantages of New Vendor Development</h2>
                </div>
            </div>
        </div>
        <div class="value-image">
            <img src="../../../assets/products/vendor-bene4.png" alt="Centered Image" />
        </div>
    </div>
</section>
<!--  -->